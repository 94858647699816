import React, {Component} from 'react';
import '../../../styles/static-web-css/bootstrap.css';
import '../../../styles/static-web-css/style.css';
import luluhk from '../../../static-web-img/luluhk.png';
import {changeLanguage} from "../../../utils/utils";

class TermsWebSc extends Component {
    
    componentDidMount () {
        const delay = ms => new Promise(res => setTimeout(res, ms));

        const script = document.createElement("script");
        script.nonce = localStorage.getItem('nonce') || 'default_nonce';
        script.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js";
        script.async = true;
        document.body.appendChild(script);

        const loadJqueryAfter = async () => {
            await delay(1000);
            const script2 = document.createElement("script");
            script2.nonce = localStorage.getItem('nonce') || 'default_nonce';
            script2.src = "https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js";
            script2.async = true;
        
            document.body.appendChild(script2);
    
            /*const script3 = document.createElement("script");
            script3.nonce = localStorage.getItem('nonce') || 'default_nonce';
            script3.src = "https://use.fontawesome.com/releases/v5.13.0/js/all.js";
            script3.async = true;
        
            document.body.appendChild(script3);*/
        };
        
        loadJqueryAfter();
    }

    render() {
        return (
            <div className="static-web-styling">
        <nav className="static-web-styling navbar navbar-expand-lg navbar-light border-bottom border-dark fixed-top" id="topnav">
            <a className="static-web-styling navbar-brand" href="/sc"><img draggable="false" src={luluhk} alt="luluhk"/></a>

            <button className="static-web-styling navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse-main">
                <span className="static-web-styling sr-only">Toggle navigation</span>
                <span className="static-web-styling navbar-toggler-icon"></span>
            </button>

            <div className="static-web-styling collapse navbar-collapse" id="navbar-collapse-main">
                <ul className="static-web-styling navbar-nav ml-auto">
                    <li className="static-web-styling nav-item"><a className="static-web-styling nav-link" href="/sc/privacy">隐私</a></li>
                    <li className="static-web-styling nav-item"><a className="static-web-styling nav-link active" href="/sc/terms">条款</a></li>
                </ul>
            </div>
        </nav>
        <div className="static-web-styling container">
            <div className="static-web-styling terms text-justify">
                <h1>条款和细则</h1>
                <p>版本：1.0 | 生效日期：2025年1月1日</p>
                <hr/>
                <p>路路香港科技有限公司（English: Lulu HK Technologies Limited）和/或其附属公司和/或关联公司（统称“路路香港”）透过luluhk.com、其子域、商家服务系统、任何其他全部或部分由路路香港开发的网站、其子域、媒体平台或应用程式包括路路香港流动应用程式（个别或统称“渠道”）提供服务。</p>
                <p>用户进入和使用渠道必须遵守本<strong>条款和细则</strong>及<strong>隐私政策</strong>。通过使用渠道和服务或创建路路香港账户，渠道的用户（无论注册与否）（简称“用户”）确认他/她/它已详细阅读和理解<strong>条款和细则</strong>及<strong>隐私政策</strong>并同意受其约束。若用户不接受任何<strong>条款和细则</strong>及<strong>隐私政策</strong>，请不要加入、访问或使用任何由我们通过渠道提供的服务。</p>
                <p>我们保留权利在没有预先通知的情况下，当我们认为必要时可随时更新、修订、更改或修改<strong>条款和细则</strong>及<strong>隐私政策</strong>。我们强烈建议用户定期查看最新的<strong>条款和细则</strong>及<strong>隐私政策</strong>。修订后的<strong>条款和细则</strong>及<strong>隐私政策</strong>会在经修订的版本发布在相应网页时立即生效。用户一旦通知我们不接受任何改动，我们会立即终止其进入渠道和服务，否则用户于上述改动后继续使用路路香港的渠道和服务将被视为接受所有改动。</p>
                
                <ol className="static-web-styling ol-h2">
                    <li className="static-web-styling ol-h2-li"><h2>定义</h2>
                        <p>在本<strong>条款和细则</strong>中，除文意另有所指外：</p>
                        <dl>
                            <div>
                                <dt>协议</dt>
                                <dd>指本<strong>条款和细则</strong>、<strong>隐私政策</strong>和任何服务订单或免费试用优惠的条款和细则；</dd>
                            </div>
                            <div>
                                <dt>授权使用者</dt>
                                <dd>指获商家授权使用商家系统的人士；</dd>
                            </div>
                            <div>
                                <dt>渠道</dt>
                                <dd>个别或统指luluhk.com、其子域、商家服务系统、任何其他全部或部分由路路香港开发的网站、其子域、媒体平台或应用程式包括路路香港流动应用程式；</dd>
                            </div>
                            <div>
                                <dt>仲裁中心</dt>
                                <dd>指香港国际仲裁中心；</dd>
                            </div>
                            <div>
                                <dt>香港</dt>
                                <dd>指香港特别行政区；</dd>
                            </div>
                            <div>
                                <dt>路路香港</dt>
                                <dd>统指路路香港科技有限公司（English: Lulu HK Technologies Limited）和/或其附属公司和/或关联公司；</dd>
                            </div>
                            <div>
                                <dt>材料</dt>
                                <dd>指在渠道上的内容、商家资料、商店资料、产品资料、照片、文本、徽标、图像、图形、连结和/或其他任何资讯和材料；</dd>
                            </div>
                            <div>                         
                                <dt>会员</dt>	
                                <dd>指在渠道上拥有注册账户的个人或实体；</dd>
                            </div>
                            <div>                         
                                <dt>商家</dt>	
                                <dd>指任何使用商家服务的组织和其关联人士；</dd>
                            </div>
                            <div>                         
                                <dt>商家服务</dt>	
                                <dd>指由我们提供给商家的商家系统和任何其他商家服务；</dd>
                            </div>
                            <div>                         
                                <dt>商家系统</dt>	
                                <dd>指由我们开发的商家服务系统；</dd>
                            </div>
                            <div>                         
                                <dt>参与分行/分店</dt>	
                                <dd>指由商家指定使用商家服务的分行或分店；</dd>
                            </div>
                            <div>                         
                                <dt>个人资料</dt>	
                                <dd>指个人资料和数据；</dd>
                            </div>
                            <div>                         
                                <dt>产品</dt>	
                                <dd>指产品和服务；</dd>
                            </div>
                            <div>                         
                                <dt>关联人士</dt>	
                                <dd>指商家的参与分行/分店、雇员和任何其他授权使用者；</dd>
                            </div>
                            <div>                         
                                <dt>服务订单</dt>	
                                <dd>指我们与一方之间订立的任何合同、报价、登记表格或服务订购表格以享有路路香港某些服务；</dd>
                            </div>
                            <div>                         
                                <dt>上传者</dt>	
                                <dd>指在渠道上上传或张贴任何材料的用户；</dd>
                            </div>
                            <div>                         
                                <dt>用户</dt>	
                                <dd>指使用渠道的个人或实体，包括非注册用户、注册用户和商家；及</dd>
                            </div>
                            <div>                         
                                <dt>浏览者</dt>	
                                <dd>指浏览和/或使用在渠道上材料的用户。</dd>
                            </div>
                        </dl>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>一般条款</h2>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li">我们根据<strong>协议</strong>透过渠道提供下列一项或多项服务：（a）作为用户浏览和分享商家的平台；（b）作为用户浏览和分享有关商家参与分行/分店的平台；（c）作为用户浏览和分享有关商家产品的平台；（d）为商家提供的商家系统；及（e）其他经渠道提供的各项功能、服务和/或应用程式；</li>
                            <li className="static-web-styling ol-li2-li">我们保留可自行决定随时以任何理由更改任何服务而不作通知之权利，包括但不限于添加、更改或删除渠道的任何部分，以及更改或删除在渠道上的任何材料；</li>
                            <li className="static-web-styling ol-li2-li">我们可能会投资于第三方的营销活动，包括但不限于Google Ads、Bing Ads、Facebook Ads、Instagram Ads和其他广告网络，以进一步宣传商家。 用户接受并同意此类促销可能不在路路香港的网域中；</li>
                            <li className="static-web-styling ol-li2-li">除非另有明确注明，路路香港不会参与任何用户之间的实际交易；</li>
                            <li className="static-web-styling ol-li2-li">我们可自行决定当我们认为有必要时随时为渠道实施预先安排及非预先安排的维护和数据备份；</li>
                            <li className="static-web-styling ol-li2-li">在用户没有签署任何服务订单而获得渠道任何功能的免费试用下，试用服务期限将由我们全权决定。此类用户进入和使用渠道必须遵守本<strong>条款和细则</strong>、<strong>隐私政策</strong>及免费试用优惠的条款（如有）；及</li>
                            <li className="static-web-styling ol-li2-li">有关我们如何使用和保护用户通过渠道上提供的个人资料，请参阅<strong>隐私政策</strong>。</li>
                        </ol><br/>
                    </li>

                    <li className="static-web-styling ol-h2-li"><h2>会员注册</h2>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li">用户可以无须注册成为会员在渠道上自由浏览供所有用户使用的材料。然而，某些渠道的材料与功能只提供给会员使用；</li>
                            <li className="static-web-styling ol-li2-li">用户保证在注册成为会员时所提供的资料都是真实、准确、完整、合法，以及在各方面都没有误导成份，并且如该资料日后有任何变更，他们理应及时更新资料；</li>
                            <li className="static-web-styling ol-li2-li">用户不可选择一个会侵犯任何一方商标或其他专有权利，或者我们认为带有攻击性或不适当的用户名。我们保留以通知要求任何用户更改其用户名的权利。若用户拒绝遵守此要求，我们有权在没有预先通知该用户的情况下暂停或终止该用户账户。在任何情况下，我们将不会就任何用户不正当使用用户名而承担任何责任；</li>
                            <li className="static-web-styling ol-li2-li">会员须独自地和全面地负责保密其用户名和密码及其账户的任何其他资料。会员应立即通知我们如发现任何未经授权而进入或使用其账户。我们不会就因未经授权使用账户而造成任何损失或损害承担责任；</li>
                            <li className="static-web-styling ol-li2-li">我们有全权及绝对酌情权拒绝任何用户注册成为会员的申请。而用户同意我们没有义务向用户发送任何拒绝会员注册申请的通知或解释；及</li>
                            <li className="static-web-styling ol-li2-li">我们保留当我们认为有必要时随时暂停或终止任何用户账户的权利（详情请参阅第15条“<strong>账户终止和材料移除</strong>”）。</li>
                        </ol><br/> 
                    </li>

                    <li className="static-web-styling ol-h2-li"><h2>受禁止的用途</h2>
                        <p>所有渠道的用户同意：</p>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li">用户如果不具有法律能力构成具有法律约束力的合同或被法律禁止进入或使用我们的服务，不应使用渠道；</li>
                            <li className="static-web-styling ol-li2-li">用户不得使用渠道以任何方式侵犯任何一方的知识产权或其他专有权利；</li>
                            <li className="static-web-styling ol-li2-li">用户不得上传、张贴、传输、分享或储存任何材料，以任何方式（a）违反任何适用法律及规例；（b）违反或侵犯他人版权、商标、商业秘密、专利、隐私、公开信息、知识产权或其他专有权利；（c）非法的、不准确的、有害的、具恐吓性的、带辱骂性的、骚扰的、侵权的、具诽谤性的、粗俗的、淫亵的、色情的、带误导性的、具憎恨性的、或在种族上、民族上或其他方面有攻击性或令人反感的；（d）用于除依据<strong>协议</strong>以外的其他商业目的；或（e）包含病毒、木马，或者可能中断、破坏或限制渠道和服务或任何电脑软、硬体或电信设备的任何素材；</li>
                            <li className="static-web-styling ol-li2-li">用户不得删除或修改其他用户发布的任何材料；</li>
                            <li className="static-web-styling ol-li2-li">用户不得改变、复制、调整、分发、发布、展示、编译、进行代码反向工程、反汇编、反编译，或尝试创造源自渠道的任何来源代码；</li>
                            <li className="static-web-styling ol-li2-li">用户禁止侵犯或企图违反渠道上的安全性，包括但不限于访问其无访问权限的数据、登入未经授权的伺服器或账户、试图探查、扫描或测试系统或网路的脆弱性、未经适当授权而试图违反安全或认证措施、企图干扰任何用户、主机或网络的服务，或者发送未经认可的电子邮件、信息、帖子或以任何其他形式发送垃圾邮件。如违反系统或网路安全可能会引致民事和/或刑事的法律责任；及</li>
                            <li className="static-web-styling ol-li2-li">用户在未经有关用户的同意下不得存取、下载、复制或使用其个人资料。</li>
                        </ol><br/>
                    </li>

                    <li className="static-web-styling ol-h2-li"><h2>可接受的用途</h2>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li"><u>特定用途 — 上传者</u>
                                <ol className="static-web-styling ol-li3">
                                    <li className="static-web-styling ol-li3-li">上传者同意他/她/它仅用渠道于合法用途以及享受渠道所提供的服务；</li>
                                    <li className="static-web-styling ol-li3-li">上传者须对其上传或发布在渠道上的材料负全部责任；</li>
                                    <li className="static-web-styling ol-li3-li">上传者接受并同意，当在渠道上上传或发布材料，这些材料都会被公开，并可能由其他渠道用户分享至第三方网站/媒体平台/应用程式；</li>
                                    <li className="static-web-styling ol-li3-li">上传者接受并同意他们/她们/它们不会因发布任何材料而获得补偿；</li>
                                    <li className="static-web-styling ol-li3-li">我们保留权利决定材料在渠道发布的期限、材料的尺寸、位置和布局；</li>
                                    <li className="static-web-styling ol-li3-li">我们保留权利当我们认为适当时随时编辑、分享及删除任何发布在渠道上的材料；</li>
                                    <li className="static-web-styling ol-li3-li">我们有权自行决定暂停或终止向任何上传者提供任何服务；及</li>
                                    <li className="static-web-styling ol-li3-li">当上传者发布材料后，如果该材料后来被他/她/它或我们删除，或者其账户已被终止，该上传者将再无法访问这些材料。然而，被删除的材料仍可能出现在渠道的任何部分和/或被我们以任何其他形式使用。</li>
                                </ol><br/>
                            </li>
                            <li className="static-web-styling ol-li2-li"><u>特定用途 — 浏览者</u>
                                <ol className="static-web-styling ol-li3">
                                    <li className="static-web-styling ol-li3-li">浏览者同意他/她/它仅用渠道于合法用途以及享受渠道所提供的服务；</li>
                                    <li className="static-web-styling ol-li3-li">浏览者同意通过渠道接收到的任何个人资料，只会用作鉴定或搜寻材料，或者使其享受渠道所提供服务。任何接收到的个人资料与上述目的无关的须被忽视，且不可以任何形式储存、使用、处理、分享、披露或传播。浏览者亦同意在上述目的已完成后，立即适当地删除任何接收到的个人资料；及</li>
                                    <li className="static-web-styling ol-li3-li">若任何浏览者违反<strong>协议</strong>，无论在香港或其他地方，使用任何其他用户的个人资料或材料作任何用途，我们将不承担任何责任。所有用户接受其公开在渠道上自愿提供的所有个人资料或材料，由其自行承担提供资料的所有风险。</li>
                                </ol><br/>
                            </li>
                            <li className="static-web-styling ol-li2-li"><u>特定用途 – 商家</u>
                                <ol className="static-web-styling ol-li3">
                                    <li className="static-web-styling ol-li3-li">商家同意它仅用渠道于合法用途以及享受渠道所提供的商家服务；</li>
                                    <li className="static-web-styling ol-li3-li">于向路路香港支付服务费或接受任何免费优惠后，商家将有权在遵守<strong>协议</strong>的条款下使用渠道来上传或发布有关其商店和产品的资料，和/或其他商业性素材；</li>
                                    <li className="static-web-styling ol-li3-li">商家可撤销、非独家、不可转授、不可转让和全球性使用商家服务的权利在<strong>协议</strong>所规定的服务期内有效，直至期限届满或服务终止，以较早者为准。服务期将会在商家自动或手动支付了下个时期的服务费后更新；</li>
                                    <li className="static-web-styling ol-li3-li">我们保留权利当我们认为适当时随时更改服务费或征收新的费用；</li>
                                    <li className="static-web-styling ol-li3-li">我们可能为不同商家提供附加系统功能。任何附加功能的使用可能需要相关商家同意此附加功能特定的额外条款和细则，而此特定条款可能会被纳入<strong>协议</strong>内；</li>
                                    <li className="static-web-styling ol-li3-li">商家保证并承诺（a）它享有法人地位的独立实体，并根据其注册所在司法管辖区的法律正式注册成立并有效存在及拥有良好的信誉；（b）它和每个参与分行/分店在它们经营所在司法管辖区内已适当地注册和持牌以提供发布在渠道上的产品，以及遵守当地所有适用法例和法规；（c）它具有签订、执行和履行<strong>协议</strong>及下文规定的义务和交易的完全企业能力、权力和法定权利；（d）<strong>协议</strong>的签订和履行不会违反任何适用法律；和（e）在其认知下，没有受到任何正在等候或知情的法律行动、起诉、程序、调查或诉讼的威胁，而任何个别或总体威胁可能对其履行<strong>协议</strong>义务的能力产生重大和不利的影响。如商家得知任何上述保证或承诺中有/将有任何错误，商家须及时通知我们；</li>
                                    <li className="static-web-styling ol-li3-li">商家须完全遵守香港法例第362章《商品说明条例》，和/或在参与分行/分店经营所在司法管辖区的其他适用法律；</li>
                                    <li className="static-web-styling ol-li3-li">商家保证发布在渠道上的产品价格（每个子产品的价格、每个选项的价格和总价）和优惠是：<ul className="static-web-styling ul-li4"><li className="static-web-styling ul-li4-li">提供给渠道的特别价格/优惠；或</li><li className="static-web-styling ul-li4-li">在参与分行/分店列出的价格/优惠；或</li><li className="static-web-styling ul-li4-li">在其他线下/线上的官方/第三方渠道列出的价格/优惠；</li></ul>价格以最低者为准和优惠以对顾客最佳者为准；</li>
                                    <li className="static-web-styling ol-li3-li">商家确保经商家系统提供任何有关商家的材料，包括但不限于产品之价格和库存数量或可得性、优惠、参与分行/分店之名称、地址、状态（正常营业、装修中、暂时停业、永久停业或已搬迁）、营业时间和联络资料，及供我们内部沟通之商家联络人资料，属准确、最新、完整、合法、有效和不侵犯任何第三方知识产权；</li>
                                    <li className="static-web-styling ol-li3-li">我们有权对任何上传到商家系统上的材料，在其发布到渠道上之前预先筛选。我们有权拒绝、编辑、删除，或者要求删除或修改任何我们自行判断为不适当或违反<strong>协议</strong>的任何条款或任何适用法律或法规之材料；</li>
                                    <li className="static-web-styling ol-li3-li">我们保留随时更改、收回或移除发布在路路香港的识别码/用户名的权利；</li>
                                    <li className="static-web-styling ol-li3-li">我们可能会披露或公布任何由商家提供而没有保密性质的材料用以推广商家服务；</li>
                                    <li className="static-web-styling ol-li3-li">商家拥有并提供给路路香港的任何标志、设计、图形和/或其他相关素材中的知识产权归有关商家所有；</li>
                                    <li className="static-web-styling ol-li3-li">商家须自行负责提供就使用商家服务所需的内部设施配置，包括但不限于流动装置和电子通讯设施负责。我们不会对此等设施配置的任何故障或维护承担任何责任；</li>
                                    <li className="static-web-styling ol-li3-li">商家须完全遵守香港法例第486章《个人资料（隐私）条例》的规定，和/或在参与分行/分店经营所在司法管辖区的其他适用法律，以适当地保护在任何情况下由我们提供或通过渠道收集的个人资料；</li>
                                    <li className="static-web-styling ol-li3-li">商家须就任何使用商家服务之关联人士违反<strong>协议</strong>的任何条款或者任何适用法律或法规而负全责。如果发现商家违反任何条款或有任何不诚实的行为，包括但不限于提供不准确的价格和库存数量信息，我们有权自行决定暂停或终止向有关商家提供任何或全部其订阅的商家服务，及/或暂停或终止其进入和使用商家系统，而毋须因此给予其任何补偿和通知；</li>
                                    <li className="static-web-styling ol-li3-li">我们有权自行决定随时修改、升级或删除商家系统的部分或全部元件而不必通知商家，商家无权拒绝上述改动。然而，如果我们对商家系统做出可能影响系统之正常运作的改动，我们将会合理地通知商家有关上述改动；</li>
                                    <li className="static-web-styling ol-li3-li">商家应当把商家系统之任何故障或缺陷及时告知我们，以及提供任何我们要求的数据或输出资料用作故障排除；</li>
                                    <li className="static-web-styling ol-li3-li">商家同意将尽其所能在参与分行/分店展示由我们提供和要求的宣传信息；</li>
                                    <li className="static-web-styling ol-li3-li">商家应赔偿并使我们免于因路路香港提供任何服务予商家，而并非基于路路香港的收入所产生之任何费用、税项或关税。商家支付的此类费用、税项和关税不应视为其向路路香港应付服务款项的一部分，或予以扣除；及</li>
                                    <li className="static-web-styling ol-li3-li">除了因（a）被任何监管、政府或司法机关/机构要求披露，或（b）基于“需要知情”的原则下须披露给律师、审计员、保险公司或会计师而这些人士此后须履行保密义务的情况之外，路路香港和商家应将另一方视为保密性质的任何资料保持机密，并不得将其披露，或者导致或允许他人将其披露给任何个人或实体。</li>
                                </ol>
                            </li>
                        </ol><br/>
                    </li>

                    <li className="static-web-styling ol-h2-li"><h2>许可</h2>
                        <p>用户授予我们无须缴付版权费用的、非独享的、全球的、不可撤销的、永久的权利和许可，在任何地方以任何形式使用、发布、执行、展示、复制、传播、推广、下载、更改、重新格式化、创作衍生作品、转移、销售，或者以任何具商业性质或非商业性质的方式利用由用户上传或发布在渠道上的任何材料，来实施载于<strong>协议</strong>所述的用途。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>知识产权</h2>
                        <p>渠道的所有内容和元素，包括不限于材料、用户界面、版面、设计、声音/音频格式、资料库、软体和HTML 均是路路香港或用户的知识产权（按情况而定），以及均受版权、商标、专利和其他适用的知识产权法律保护。用户可下载及/或复制此类内容和元素作为其个人合法和非商业用途或<strong>协议</strong>明确允许的其他用途。用户严格禁止使用任何上述之内容和元素作为除<strong>协议</strong>明确允许以外的任何用途，我们保留所有权利执行此等内容和元素中的所有知识产权。</p>
                        <p>为免生疑问，任何第三方（包括商家）声称同意使用<strong>协议</strong>提到的内容或其任何部分并不使用户以任何方式免于<strong>协议</strong>所规定的限制和禁止。</p>
                        <p>用户同意我们可以自由地使用、披露、采用及/或更改由用户提供给路路香港任何与使用渠道和服务有关的资料，包括但不限于任何材料、想法、概念、提案、建议及评论，而毋须因此给予其任何报酬。用户谨此放弃就我们使用、披露、采用及/或更改此等资料而向我们索取任何收费、特许权使用费、手续费及/或其他报酬的权利和索赔。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>第三方网站/媒体平台/应用程式的连结</h2>
                        <p>渠道可能会提供非由我们拥有或管理的第三方网站/媒体平台/应用程式的连结。这些在渠道上提供的连结仅为了便利用户及作为互联网的导航工具，并绝不以任何方式视为我们对该等第三方网站/媒体平台/应用程式之内容的认可。除非另有注明，我们对此等第三方网站/媒体平台/应用程式没有任何控制权，以及我们不对其内容、准确性和安全性负责。当用户决定点击渠道上允许用户进入一个第三方网站/媒体平台/应用程式的任何连结时，他/她/它必须自行承担所有风险。我们不会就用户因使用该等第三方网站/媒体平台/应用程式而造成的损失或损害负责或承担任何责任。此外，用户在渠道上上传或发布的任何材料可能会被连结到渠道的其他网站/媒体平台/应用程式之使用者浏览，我们并不会对任何使用者或第三方通过第三方网站/媒体平台/应用程式而不当及/或非法地使用该等材料承担任何责任。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>用户与商家的往来</h2>
                        <p>用户与商家在渠道上或通过渠道进行的任何交流往来或参与其促销活动，包括支付及交付相关产品或服务，以及与此往来或促销相关的任何其他条款、细则、保证或声明，均仅在该用户与商家之间进行。我们不对因任何该等往来或促销，或者因商家发布之任何材料的准确性而引致的任何损失或损害负责或承担任何责任。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>责任</h2>
                        <p>我们或不会在任何时间都监控渠道但保留此权利。我们不会对非由我们发布在渠道上或通过渠道发布的任何材料承担任何责任。用户接受并同意对其提交至路路香港，或者其发布在渠道上或通过渠道发布的任何材料之形式、内容及准确性承担全部责任。我们不保证任何材料将得到任何特定数目的用户观看或将被任何特定用户观看。</p>
                        <p>我们不对商家提供之产品的品质及合法性负责。我们不保证由任何商家发布在渠道上之资料的准确性。如果商家与用户之间发生任何争议，他们双方应彼此之间解决此等争议，我们并不会对此等争议负责或承担任何责任。</p>
                        <p>就渠道的使用上，我们不应被视为任何用户或商家的代理人。我们对使用渠道和服务的任何一方因任何原因造成直接或间接的损害不会承担任何责任。</p>
                        <p><strong>尽管我们竭力向所有用户提供优质服务，但不保证（a）渠道之运营毫无失误，或者免受病毒或其他损害装置之侵袭；（b）任何缺陷将得到纠正；（c）渠道之运营将不间断、及时或安全。若用户因使用渠道或服务导致其须要替换设备或数据，我们不会对此等费用及任何性质的损失或损害负责。渠道和其内容是按照“目前的模式”提供，在模式种类上没有任何保证。在法律允许的最大限度内，我们拒绝承认一切保证，包括但不限于任何以下的保证（a）适销性；（b）特定目的之适用性；（c）不侵犯第三方权利；（d）特色产品之可用性；及（e）渠道上的材料之准确性、可靠性、完整性或及时性。用户应独立评估并核证渠道材料之准确性、完整性及可靠性，并有必要时寻求专业意见。</strong></p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>自身风险</h2>
                        <p><strong>所有用户须就使用渠道及通过渠道浏览任何其他的网站/媒体平台/应用程式而承担自身风险</strong>。 所有用户须对其在渠道上的通讯负责并承担其在渠道上活动的后果。我们不代表或保证任何由用户上传或发布的材料之准确性和可靠性，亦不代表我们赞同上传者表达的观点。所有用户依赖其他用户张贴的任何材料时应由其承担自身风险。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>赔偿</h2>
                        <p>在法律允许的最大限度内，所有用户同意维护、保障及免除路路香港、其执行官、董事、雇员、代理人、合作商、代表、股东、服务人员、律师、前任人员、继任人员和受让人，因其使用渠道或通过渠道使用服务，或违反<strong>协议</strong>的任何条款，或与之有关而产生的任何索赔、要求、成本、责任、损失、损害和费用的责任（包括但不限于全额赔偿基准上的法务费用）。如有任何此类索赔、诉讼、行动或法律程序，我们将及时通知相关用户。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>免责声明</h2>
                        <p><strong>在法律允许的最大限度内，路路香港、其执行官、董事、雇员、代理人、合作商、代表、股东、服务人员、律师、前任人员、继任人员和受让人绝不须要因（a）任何用户使用或无法使用渠道和服务，包括但不限于上传在渠道或透过渠道上传之任何材料的任何不正确、错误、遗漏、中断、删除、传输延误或丢失、因疏忽及因各方的分歧而无法使用；（b）任何用户使用或无法使用任何商家提供的产品；和/或（c）任何商家的政策或行为，或与之有关而直接或间接引起或造成用户的任何损失或损害赔偿（包括但不限于直接、 间接、 偶然、 特殊、告诫性、惩罚性或后果性的损害赔偿）承担任何责任。</strong></p>
                        <p><strong>路路香港绝不是任何用户或商家的代理。</strong></p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>系统可用性</h2>
                        <p>我们不能完全保证用户可不间断、连续或安全地通过渠道访问任何服务。部分或全部渠道可能出于任何原因于某段时间内意外地无法访问，这些原因包括但不限于系统故障及中断、网络攻击及其他不可抗力的事故，我们对此原因概不负责（详情请参阅第18条“<strong>不可抗力的事故</strong>”）。我们保留因不同原因，包括但不限于系统维修或升级，而将渠道的任何部分或全部离线而不作通知的权利。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>账户终止和材料移除</h2>
                        <p>我们保留权力随时不作通知下以任何理由，包括但不限于违反<strong>协议</strong>、提供不准确的资料或不适当的材料及违反法例和规定，以暂停或终止其会员账户、限制或终止其访问服务，或者封锁其互联网通信协定位址的方式，驱逐任何用户并暂时或永久禁止其再次访问渠道和服务。我们亦保留权利随时不作通知下删除任何具有辱骂性、非法性、分裂性或不适当的材料。</p>
                        <p>我们有全权及绝对酌情权决定用户或材料有否违反了任何<strong>协议</strong>的条款及是否须要采取进一步的行动，包括但不限于对此用户采取法律行动，而此决定应为最终的。如果我们没有行使本条下的权利，其不应被视为对此权利的放弃。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>责任范围</h2>
                        <p>在不影响上文及符合适用法律的基础上，路路香港对任何用户因使用渠道和服务或与之有关而提出的所有索偿的整体责任上限为路路香港所收到导致此索偿主张之服务的费用。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>适用法律及司法管辖权</h2>
                        <p>除非另有注明，此<strong>条款和细则</strong>须受香港的法例规管，并据此进行诠释。</p>
                        <p>任何由此<strong>条款和细则</strong>或与之有关，或由于此<strong>条款和细则</strong>的违反、终止或无效而引起或与之有关的争议、分歧或索赔，应根据截至目前生效的《联合国国际贸易法委员会仲裁规则》并通过此条款其余条文修正而仲裁解决：</p>
                        <ol className="static-web-styling ol-li2"><li className="static-web-styling ol-li2-li">指派机关应为仲裁中心；</li>
                            <li className="static-web-styling ol-li2-li">仲裁地点应设在香港的仲裁中心；</li>
                            <li className="static-web-styling ol-li2-li">需只有一名仲裁员；及</li>
                            <li className="static-web-styling ol-li2-li">在仲裁过程中使用的语言为英语。</li></ol><br/>
                        <p>如任何一方违反此<strong>条款和细则</strong>，另一方有权根据仲裁认定的普通法和衡平法采取补救措施。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>不可抗力的事故</h2>
                        <p>在不影响<strong>协议</strong>中任何限制责任的条款下，我们不对因超出我们控制范围的情形而直接或间接导致履行义务的任何延迟或失败而承担任何责任。此不可抗力的事故包括但不限于互联网中断、电脑设备故障、电信设备故障或其他设备故障、停电、罢工、劳资纠纷、暴乱、叛乱、民间动乱、劳工或素材短缺、火灾、水灾、暴风、爆炸、天灾、战争、政府行为、国内或国外法院或法庭命令、第三方的服务不履行及热力、照明或空调供应的损失或波动。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>完整协定</h2>
                        <p><strong>协议</strong>构成路路香港与用户就使用我们的渠道和服务所订立的完整协定，并取代所有先前与该标的有关的一切书面或口头的讨论、协定、谈判和通讯。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>可分割性</h2>
                        <p>本<strong>条款和细则</strong>的每一条款独立于其他条款，其效力不受其他条款之无效性所影响。当<strong>条款和细则</strong>的任何条款或条款的任何部分已属或变成违法、无效或不可执行时，<strong>条款和细则</strong>的其他条款或条款的剩余部分的有效性和强制性不受影响，而为取代该等不合法、无效或不可执行的条款或条款的其中部分，须加入一项或更多相近而按照适用法例属于合法、有效而且可予执行的条文，作为<strong>条款和细则</strong>的一部分。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>第三方权利</h2>
                        <p>其他非本<strong>条款和细则</strong>的有关人士均没有强制执行或享有本<strong>条款和细则</strong>中任何条款利益的权利。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>优先顺序</h2>
                        <p>若此<strong>条款和细则</strong>、<strong>隐私政策</strong>、任何渠道之特定使用条款和任何服务合同之条款和细则，包括但不限于服务订单和路路香港主办任何特殊活动的任何具体使用条款，之间存在任何冲突，除非另有注明，其递减优先顺序如下：</p>
                        <ol className="static-web-styling ol-li2"><li className="static-web-styling ol-li2-li">任何服务合同之条款和细则；</li>
                            <li className="static-web-styling ol-li2-li"><strong>隐私政策</strong>；</li>
                            <li className="static-web-styling ol-li2-li">任何渠道之特定使用条款；及</li>
                            <li className="static-web-styling ol-li2-li">此<strong>条款和细则</strong>。</li></ol><br/>
                    </li>

                    <li className="static-web-styling ol-h2-li"><h2>主导语言</h2>
                        <p>如英文版本与其他语言的版本有任何抵触或不相符之处，概以英文版本为准。</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>联络方法</h2>
                        <p>如对这<strong>条款和细则</strong>有任何疑问，请透过以下途径联络本公司的客户服务代表：</p>
                        <address><strong>路路香港科技有限公司</strong><br/>
                        电邮地址：<a href = "mailto: contact@luluhk.com">contact@luluhk.com</a></address>
                    </li>
                </ol>
            </div>
        </div>

        <nav className="static-web-styling navbar navbar-expand navbar-light bg-light border-top border-dark fixed-bottom" id="bottomnav">
            <div className="static-web-styling collapse navbar-collapse">
                <ul className="static-web-styling navbar-nav mr-auto">
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link" href="/en/terms" lang="en" hrefLang="en" onClick={()=>changeLanguage("en")}>EN</a></li>
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link" href="/tc/terms" lang="zh-Hant" hrefLang="zh-Hant" onClick={()=>changeLanguage("zh_Hant")}>繁</a></li>
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link active" href="/sc/terms" onClick={()=>changeLanguage("zh_Hans")}>简</a></li>
                </ul>
                <span className="static-web-styling copyright">&copy; 2025 路路香港科技有限公司</span>
            </div>
        </nav>  
            </div>
        )
    }
}

export default TermsWebSc;
